@charset "utf-8";
@import "../lib/reset.min.css";
@import "../_icons.scss";
@import "../_variable.sass";
@import "../_mixin.sass";

.is-sp {
  display: none; }

.m-title {
  text-align: center;
  margin-bottom: 72px;

  .titleJa {
    @include setJa;
    color: $black;
    font-size: 24px;
    margin-bottom: 20px; }

  .titleEn {
    @include setEn;
    color: $blue;
    font-size: 14px; } }

.m-txt {
  @include setFont(14px, 2);
  color: $black;
  letter-spacing: 0.05em; }

.m-brand {
  li {
    position: relative;
    top: -3px;
    display: inline-block;
    opacity: 0.4;

    &+li {
      margin-left: 24px; }

    img {
      height: 20px; } } }

.m-tag {
  margin-top: -8px;
  display: flex;
  flex-wrap: wrap;

  li {
    margin: 8px 8px 0 0;

    a {
      color: $blue;
      font-size: 10px;
      font-feature-settings: 'palt';
      text-decoration: none;
      letter-spacing: 0.05em;
      padding: 4px 8px;
      border: 1px solid $blue;
      border-radius: 20px;
      display: inline-block;
      box-sizing: border-box;

      &:hover {
        color: $white;
        background: $blue; } } } }

.m-btn {
  a {
    @include setEn;
    color: $blue;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    border: 1px solid $blue;
    display: block;
    box-sizing: border-box;

    &:hover {
      color: $white;
      background: $blue; } } }

.m-link {
  a {
    color: $blue;
    font-size: 10px;
    font-feature-settings: 'palt';
    text-decoration: none;
    letter-spacing: 0.05em;
    padding: 4px 8px;
    border: 1px solid $blue;
    border-radius: 20px;
    display: inline-block;
    box-sizing: border-box;

    &:hover {
      color: $white;
      background: $blue; } } }

.m-itemList {
  display: flex;
  flex-wrap: wrap;

  &>li {
    width: calc(33.3% - 1px);
    margin-bottom: 80px;

    &+li {
      margin-left: 1px; }

    &>a {
      text-decoration: none;
      width: 100%;
      padding: 0 48px;
      box-sizing: border-box;
      display: block;

      .thumb {
        @include setImg(calc(100% + 96px));
        height: calc((100vw - 96px) / 3 * 0.71);
        background: $gray-e;
        box-sizing: border-box;
        margin: 0 0 40px -48px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 280px; } }

      .brand {
        margin-bottom: 32px;
        padding: 0; }

      .title {
        @include setJa;
        color: $black;
        @include setFont(21px, 1.5, 32px); }

      .txt {
        @include setMargin(14px, 2, 36px); }

      .date {
        @include setEn;
        color: $blue;
        font-size: 14px;
        margin-bottom: 32px;
        display: block; }

      .brand,
      .date {
        &:last-child {
          margin-bottom: 0; } }

      &:hover {
        .thumb {
          opacity: 0.5; }

        .title,
        .txt {
          color: $blue; } } }

    .tag,
    .brand {
      padding: 0 48px; }

    .tag {
      &+.brand {
        margin-top: 32px; } } } }

.m-gallery {
  width: 100%;
  position: relative;
  overflow: hidden;

  .imgs {
    width: 1000%;
    background: $gray-e;
    margin-bottom: 24px;
    display: flex;

    li {
      width: calc(100vw - 532px);
      text-align: center;

      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 420px;
        position: relative;
        top: 50%;
        transform: translateY(-50%); } } }

  .captions {
    margin-bottom: 20px;

    li {
      @include setFont(14px, 1.5);
      color: $gray-a;
      display: none;

      &.is-active {
        display: block; } } }

  .prev,
  .next {
    width: 40px;
    height: 40px;
    position: absolute;
    top: calc(50% - 52px);
    z-index: 1;
    cursor: pointer;

    &:hover {
      &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: $white;
        opacity: 0.25; } }

    &.is-disable {
      background-color: $gray-a;
      pointer-events: none; } }

  .prev {
    background: $blue url(../../images/common/icn_arrow_w.svg) no-repeat 50% 50%;
    left: 0;
    transform: scale(-1, 1); }

  .next {
    background: $blue url(../../images/common/icn_arrow_w.svg) no-repeat 50% 50%;
    right: 0; }

  .page {
    color: $gray-a;
    font-size: 12px;
    text-align: right; } }

.m-tab {
  margin-bottom: 88px;
  display: flex;

  li {
    width: 14.4%;
    text-align: center;
    position: relative;
    margin-left: -1px;

    &:after {
      content: '';
      width: 1px;
      height: 20px;
      background: $gray-c;
      position: absolute;
      top: 20px;
      right: 0; }

    &.is-active,
    &:last-child {
      &:after {
        display: none; } }

    &.is-active {
      pointer-events: none;

      a {
        color: $white;
        background: $blue;

        img {
          filter: brightness(0) invert(100%);
          opacity: 1; } } }

    a {
      @include setEn;
      color: $gray-a;
      font-size: 14px;
      text-decoration: none;
      line-height: 55px;
      width: 100%;
      height: 56px;
      display: block;
      box-sizing: border-box;
      padding: 0 16px;

      img {
        max-width: 100%;
        height: 24px;
        opacity: 0.5; }

      &:hover {
        opacity: 0.5; } } } }

.m-select {
  margin-bottom: 88px;
  display: flex;
  justify-content: center;

  li {
    width: calc(33.3% - 1px);
    position: relative;

    &+li {
      margin-left: 20px; }

    select {
      width: 100%;
      height: 56px;
      background: $gray-e;
      border: 0;
      padding: 0 24px;
      appearance: none; }

    &:after {
      content: '';
      width: 10px;
      height: 8px;
      background: url(../../images/common/icn_arrow.svg) no-repeat 50% 50%;
      position: absolute;
      top: 24px;
      right: 24px;
      pointer-events: none; } } }

.m-pager {
  margin-top: 24px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;

  .btn {
    display: inline-block;

    &.prev {
      margin-right: 60px; }

    &.next {
      margin-left: 60px; }

    a {
      font-size: 12px;
      line-height: 40px;
      width: 96px;
      height: 40px;
      border-radius: 20px; }

    &.is-disable {
      a {
        color: $gray-c;
        border-color: $gray-c;
        pointer-events: none; } } }

  .num {
    display: inline-block;

    a {
      @include setEn;
      color: $black;
      font-size: 14px;
      text-align: center;
      text-decoration: none;
      line-height: 40px;
      width: 40px;
      height: 40px;
      background: $gray-e;
      margin-right: 1px;
      display: block;

      &:hover {
        color: $white;
        background: $blue; } }

    &.is-active {
      a {
        color: $white;
        background: $blue;
        pointer-events: none; } } }

  .trim {
    color: $black;
    font-size: 14px;
    line-height: 40px;
    margin: 0 16px;
    display: inline-block; } }

.m-other {
  margin: 96px 0 96px;
  border-bottom: 1px solid $gray-c;
  padding-bottom: 88px;

  .btn {
    a {
      line-height: 64px;
      width: 418px;
      height: 64px;
      border-radius: 32px;
      margin: 0 auto; } } }

.m-empty {
  padding: 80px 0;

  .txt {
    @include setJa;
    @include setFont(24px, 1.5);
    text-align: center; } }

.m-vision {
  min-height: 768px;
  padding: 108px 0 128px 56px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;

  .titleEn {
    @include setEn;
    color: $blue;
    margin-bottom: 56px; }

  .titleJa {
    margin-bottom: 56px;
    position: relative;
    left: -3px; }

  .copy {
    margin-bottom: 56px; }

  .txt {
    width: 516px; }

  .img {
    width: 1366-112px;
    height: 96*8px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    @media (min-width:1366px) {
      left: auto;
      right: 0; }

    .i {
      width: 96px;
      height: 96px;
      position: absolute;
      overflow: hidden;

      &:before {
        content: '';
        width: 96*2px;
        height: 96*2px;
        position: absolute;
        top: 0;
        left: 0; }

      &:nth-child(odd) {
        &:before {
          animation: bgAnim1 30s linear infinite; } }

      &:nth-child(even) {
        &:before {
          animation: bgAnim2 30s linear infinite; } }

      &:nth-child(1) {
        top: 96*0px;
        right: 96*5px;

        &:before {
          background: url(../../images/index/bg_vision_e1.png) repeat 0 0;
          background-size: 96px 96px; } }

      &:nth-child(2) {
        top: 96*0px;
        right: 96*1px;

        &:before {
          background: url(../../images/index/bg_vision_a4.png) repeat 0 0;
          background-size: 96px 96px; } }

      &:nth-child(3) {
        top: 96*1px;
        right: 96*7px;

        &:before {
          background: url(../../images/index/bg_vision_a2.png) repeat 0 0;
          background-size: 96px 96px; } }

      &:nth-child(4) {
        top: 96*1px;
        right: 96*2px;

        &:before {
          background: url(../../images/index/bg_vision_e2.png) repeat 0 0;
          background-size: 96px 96px; } }

      &:nth-child(5) {
        top: 96*2px;
        right: 96*4px;

        &:before {
          background: url(../../images/index/bg_vision_g4.png) repeat 0 0;
          background-size: 96px 96px; } }

      &:nth-child(6) {
        top: 96*2px;
        right: 96*2px;

        &:before {
          background: url(../../images/index/bg_vision_b3.png) repeat 0 0;
          background-size: 96px 96px; } }

      &:nth-child(7) {
        top: 96*2px;
        right: 96*1px;

        &:before {
          background: url(../../images/index/bg_vision_d1.png) repeat 0 0;
          background-size: 130px 130px;
          width: 130*2px;
          height: 130*2px;
          animation: bgAnimD1 30s linear infinite; } }

      &:nth-child(8) {
        top: 96*2px;
        right: 96*0px;

        &:before {
          background: url(../../images/index/bg_vision_g3.png) repeat 0 0;
          background-size: 96px 96px; } }

      &:nth-child(9) {
        top: 96*3px;
        right: 96*3px;

        &:before {
          background: url(../../images/index/bg_vision_e2.png) repeat 0 0;
          background-size: 96px 96px; } }

      &:nth-child(10) {
        top: 96*3px;
        right: 96*2px;

        &:before {
          background: url(../../images/index/bg_vision_g1.png) repeat 0 0;
          background-size: 96px 96px; } }

      &:nth-child(11) {
        top: 96*3px;
        right: 96*1px;

        &:before {
          background: url(../../images/index/bg_vision_c3.png) repeat 0 0;
          background-size: 96px 96px; } }

      &:nth-child(12) {
        top: 96*4px;
        right: 96*4px;

        &:before {
          background: url(../../images/index/bg_vision_b1.png) repeat 0 0;
          background-size: 96px 96px; } }

      &:nth-child(13) {
        top: 96*4px;
        right: 96*3px;

        &:before {
          background: url(../../images/index/bg_vision_f4.png) repeat 0 0;
          background-size: 96px 96px; } }

      &:nth-child(14) {
        top: 96*4px;
        right: 96*2px;

        &:before {
          background: url(../../images/index/bg_vision_d3.png) repeat 0 0;
          background-size: 34px 34px;
          animation: bgAnimD3 30s linear infinite; } }

      &:nth-child(15) {
        top: 96*4px;
        right: 96*0px;

        &:before {
          background: url(../../images/index/bg_vision_f3.png) repeat 0 0;
          background-size: 96px 96px; } }

      &:nth-child(16) {
        top: 96*5px;
        right: 96*4px;

        &:before {
          background: url(../../images/index/bg_vision_g2.png) repeat 0 0;
          background-size: 96px 96px; } }

      &:nth-child(17) {
        top: 96*5px;
        right: 96*2px;

        &:before {
          background: url(../../images/index/bg_vision_c2.png) repeat 0 0;
          background-size: 96px 96px; } }

      &:nth-child(18) {
        top: 96*5px;
        right: 96*0px;

        &:before {
          background: url(../../images/index/bg_vision_e3.png) repeat 0 0;
          background-size: 96px 96px; } }

      &:nth-child(19) {
        top: 96*6px;
        right: 96*5px;

        &:before {
          background: url(../../images/index/bg_vision_a3.png) repeat 0 0;
          background-size: 96px 96px; } }

      &:nth-child(20) {
        top: 96*6px;
        right: 96*3px;

        &:before {
          background: url(../../images/index/bg_vision_c4.png) repeat 0 0;
          background-size: 96px 96px; } }

      &:nth-child(21) {
        top: 96*6px;
        right: 96*1px;

        &:before {
          background: url(../../images/index/bg_vision_f2.png) repeat 0 0;
          background-size: 96px 96px; } }

      &:nth-child(22) {
        top: 96*6px;
        right: 96*0px;

        &:before {
          background: url(../../images/index/bg_vision_d2.png) repeat 0 0;
          background-size: 68px 68px;
          animation: bgAnimD2 30s linear infinite; } }

      &:nth-child(23) {
        top: 96*7px;
        right: 96*8px;

        &:before {
          background: url(../../images/index/bg_vision_c1.png) repeat 0 0;
          background-size: 96px 96px; } }

      &:nth-child(24) {
        top: 96*7px;
        right: 96*6px;

        &:before {
          background: url(../../images/index/bg_vision_b4.png) repeat 0 0;
          background-size: 96px 96px; } }

      &:nth-child(25) {
        top: 96*7px;
        right: 96*2px;

        &:before {
          background: url(../../images/index/bg_vision_f1.png) repeat 0 0;
          background-size: 96px 96px; } }

      &:nth-child(26) {
        top: 96*7px;
        right: 96*1px;

        &:before {
          background: url(../../images/index/bg_vision_d4.png) repeat 0 0;
          background-size: 17px 17px;
          animation: bgAnimD4 30s linear infinite; } }

      &:nth-child(27) {
        top: 96*7px;
        right: 96*0px;

        &:before {
          background: url(../../images/index/bg_vision_b2.png) repeat 0 0;
          background-size: 96px 96px; } } } } }

@keyframes bgAnim1 {
  0% {
    transform: translate3d(-96px,-96px,0); }
  100% {
    transform: translate3d(0,0,0); } }

@keyframes bgAnim2 {
  0% {
    transform: translate3d(0,0,0); }
  100% {
    transform: translate3d(-96px,-96px,0); } }

@keyframes bgAnimD1 {
  0% {
    transform: translate3d(-130px,-130px,0); }
  100% {
    transform: translate3d(0,0,0); } }

@keyframes bgAnimD2 {
  0% {
    transform: translate3d(0,0,0); }
  100% {
    transform: translate3d(-68px,-68px,0); } }

@keyframes bgAnimD3 {
  0% {
    transform: translate3d(0,0,0); }
  100% {
    transform: translate3d(-85px,-85px,0); } }

@keyframes bgAnimD4 {
  0% {
    transform: translate3d(0,0,0); }
  100% {
    transform: translate3d(-93px,-93px,0); } }





