@charset "utf-8";
@import "../lib/reset.min.css";
@import "../_icons.scss";
@import "../_variable.sass";
@import "../_mixin.sass";

body.index {
  .l-visual {
    margin-bottom: 96px;
    position: relative;

    .list {
      &>li {
        display: none;

        &.is-active {
          display: block; }

        a {
          display: block;
          position: relative;

          .img {
            @include setImg(100%);
            height: calc((100vw - 168px) * 0.56);
            padding-right: 56px;
            box-sizing: border-box;
            overflow: hidden;
            text-align: center;
            &.booksize {
              img {
                width: 60%; } } }

          .txts {
            width: 628px;
            background: $white;
            box-sizing: border-box;
            padding: 56px 0 4px 56px;
            position: absolute;
            right: 0;
            bottom: 0;

            .info {
              display: flex;

              .date {
                @include setEn;
                color: $blue;
                font-size: 14px;
                display: inline-block;
                margin-right: 20px;
                padding-right: 20px;
                position: relative;

                &:after {
                  content: '';
                  width: 1px;
                  height: 14px;
                  background: $gray-c;
                  position: absolute;
                  top: 1px;
                  right: 0; } } }

            .title {
              @include setJa;
              @include setFont(28px, 1.5, 40px, 40px);
              color: $black; } }

          &:hover {
            .title,
            .txt {
              color: $blue; } } } } }

    .prev,
    .next {
      width: 32px;
      height: 32px;
      position: absolute;
      top: calc(50% - 16px);
      z-index: 1;
      cursor: pointer;

      &:hover {
        &:before {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: $white;
          opacity: 0.25; } }

      &.is-disable {
        background-color: $gray-a;
        pointer-events: none; } }

    .prev {
      background: $blue url(../../images/common/icn_arrow_w.svg) no-repeat 50% 50%;
      background-size: 6px 8px;
      left: 0;
      transform: scale(-1, 1); }

    .next {
      background: $blue url(../../images/common/icn_arrow_w.svg) no-repeat 50% 50%;
      background-size: 6px 8px;
      right: 0; }

    .transition {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      display: none;
      flex-wrap: wrap;
      overflow: hidden;

      .i {
        background: $blue;
        width: calc((100vw - 112px) / 8);
        height: calc((100vw - 112px) / 8);
        opacity: 0; } } }

  .l-information {
    table {
      width: 50%;
      min-width: 700px;
      margin: 0 auto;
      margin-bottom: 72px;
      line-height: 2;
      th {
        height: 24px;
        color: $blue;
        font-family: 'PT Serif',sans-serif;
        font-size: 14px;
        display: inline-block;
        margin-right: 30px;
        position: relative;
        letter-spacing: .05em; }
      td {
        a {
          width: 600px;
          color: $black;
          font-size: 14px;
          display: inline-block;
          text-decoration: none;
          letter-spacing: .05em;
          &:hover {
            color: $blue; } } } } }


  .l-vision {
    margin-bottom: 120px; }

  .l-brands {
    margin-bottom: 118px;

    .list {
      display: flex;
      flex-wrap: wrap;

      .item {
        width: calc((100% - 28px) / 2);
        margin-bottom: 24px;
        padding: 80px 48px 152px;
        display: block;
        position: relative;
        box-sizing: border-box;

        .logo {
          text-align: center;
          margin-bottom: 64px;

          img {
            height: 44px; } }

        .btn {
          position: absolute;
          left: 50%;
          bottom: 64px;
          transform: translateX(-50%);

          a {
            font-size: 14px;
            line-height: 40px;
            width: 192px;
            height: 40px;
            border-radius: 20px; } }

        &.bijutsuTecho {
          background: url(../../images/common/bg_brand_bijutsu_techo.png) repeat 0 0;
          background-size: 384px 192px; }

        &.designCenter {
          background: url(../../images/common/bg_brand_design_center.png) repeat 0 0;
          background-size: 384px 192px; }

        &.winart {
          background: url(../../images/common/bg_brand_winart.png) repeat 0 0;
          background-size: 384px 192px; }

        &.bijutsuKentei {
          background: url(../../images/common/bg_brand_bijutsu_kentei.png) repeat 0 0;
          background-size: 384px 192px; }

        &:nth-child(1),
        &:nth-child(3) {
          margin-right: 28px; } } }

    .businessSolution {
      background: url(../../images/common/bg_brand_business_solution.png) repeat 0 0;
      background-size: 384px 192px;
      margin-top: 0px;
      padding: 80px 48px 80px 50%;
      position: relative;

      .logo {
        text-align: center;
        margin-bottom: 64px;
        position: absolute;
        top: 50%;
        left: 25%;
        transform: translate(-50%, -50%);

        img {
          height: 56px; } }

      .btn {
        margin-top: 48px;

        a {
          font-size: 14px;
          line-height: 40px;
          width: 192px;
          height: 40px;
          margin: 0 auto;
          border-radius: 20px; } } } }

  .l-news {
    .btn {
      a {
        line-height: 64px;
        width: 418px;
        height: 64px;
        border-radius: 32px;
        margin: 0 auto; } } } }
