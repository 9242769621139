@charset "utf-8";
@import "../lib/reset.min.css";
@import "../_icons.scss";
@import "../_variable.sass";
@import "../_mixin.sass";

body.works,
body.news {
  .l-visual {
    margin-bottom: 84px;

    .img {
      width: 100%;
      margin-bottom: 80px;
      text-align: center;
      background: $gray-e;

      img {
        max-width: 100%;
        max-height: 720px;
        width: auto;
        height: auto; } }

    .txts {
      width: 100%;
      padding: 0 56px;
      box-sizing: border-box;
      background: url(../../images/common/bg_title_1c_pc.png) no-repeat 100% 0;
      background-size: 576px 288px;

      &.bijutsuTecho {
        background: url(../../images/common/bg_title_bijutsu_techo_1c_pc.png) no-repeat 100% 0;
        background-size: 576px 288px; }

      &.businessSolution {
        background: url(../../images/common/bg_title_business_solution_1c_pc.png) no-repeat 100% 0;
        background-size: 576px 288px; }

      &.designCenter {
        background: url(../../images/common/bg_title_design_center_1c_pc.png) no-repeat 100% 0;
        background-size: 576px 288px; }

      &.winart {
        background: url(../../images/common/bg_title_winart_1c_pc.png) no-repeat 100% 0;
        background-size: 576px 288px; }

      &.bijutsuKentei {
        background: url(../../images/common/bg_title_bijutsu_kentei_1c_pc.png) no-repeat 100% 0;
        background-size: 576px 288px; }

      .info {
        display: flex;

        .date {
          @include setEn;
          color: $blue;
          font-size: 14px;
          display: inline-block;
          margin-right: 20px;
          padding-right: 20px;
          position: relative;

          &:after {
            content: '';
            width: 1px;
            height: 14px;
            background: $gray-c;
            position: absolute;
            top: 1px;
            right: 0; } }

        .tag {
          margin-left: 24px;
          padding-left: 24px;
          position: relative;

          &:after {
            content: '';
            width: 1px;
            height: 14px;
            background: $gray-c;
            position: absolute;
            top: 10px;
            left: 0; } } }

      .title {
        @include setJa;
        @include setFont(40px, 1.5, 56px, 40px);
        color: $black;
        text-align: justify;
        word-break: break-all; }

      .txt {
        @include setFont(16px, 2);
        letter-spacing: 0.05em;
        text-align: justify;
        word-break: break-all; } } }

  .l-article {
    display: flex;

    .l-main,
    .l-sub {
      border-top: 1px solid $gray-c; }

    .l-main {
      margin: 0 56px -56px 0;
      padding: 0 56px;
      width: calc(100% - 420px);
      box-sizing: border-box;

      &>*:first-child {
        margin-top: 80px !important; }

      h1 {
        @include setJa;
        @include setFont(32px, 1.5, 56px, 80px); }

      h2 {
        @include setJa;
        @include setFont(24px, 1.5, 56px, 56px); }

      img {
        max-width: 100%;
        height: auto; }

      hr {
        width: calc(100% + 112px);
        margin: 80px 0 80px -56px; }

      .txt {
        @include setFont(16px, 2, 56px, 56px);
        letter-spacing: 0.05em;
        text-align: justify;
        word-break: break-all;

        a {
          color: $blue;
          text-decoration: none;

          &:before {
            content: '';
            background: url(../../images/common/icn_link.svg) no-repeat 0 0;
            width: 16px;
            height: 16px;
            display: inline-block;
            position: relative;
            top: 2px;
            margin-right: 6px; }

          &:hover {
            opacity: 0.5; } } }

      &>.img {
        @include setImg(calc(100% + 112px));
        margin: 80px 0 80px -56px; }

      &>.caption {
        @include setFont(14px, 1.5, 80px, -56px);
        width: calc(100% + 112px);
        color: $gray-a;
        text-align: center;
        margin-left: -56px; }

      .m-gallery {
        width: calc(100% + 112px);
        margin: 80px 0 80px -56px; } }

    .l-sub {
      width: 364px;

      &>*:first-child {
        margin-top: 80px !important; }

      .btn {
        margin-bottom: 64px;

        a {
          line-height: 64px;
          height: 64px;
          border-radius: 32px; } }

      .titleJa {
        @include setJa;
        @include setFont(21px, 1.5, 32px, 56px);
        color: $black; }

      .titleEn {
        @include setEn;
        color: $blue;
        font-size: 18px;
        margin-bottom: 32px; }

      .credit {
        @include clearfix;

        dt {
          @include setEn;
          @include setFont(14px, 1.5, 20px);
          color: $gray-a;
          width: 114px;
          float: left;
          clear: both; }

        dd {
          @include setFont(14px, 1.5, 20px);
          color: $gray-a;
          float: left; } }

      .list {
        dt {
          @include setFont(14px, 1.5, 16px);
          color: $blue; }

        dd {
          @include setFont(14px, 1.5, 24px);
          color: $gray-a; } }

      .link {
        &+.link {
          margin-top: 12px; } } } } }
