@charset "utf-8";
@import "../lib/reset.min.css";
@import "../_icons.scss";
@import "../_variable.sass";
@import "../_mixin.sass";

body.business {
  .l-vision {
    margin-bottom: 112px;

    .titleEn {
      margin-bottom: 48px; }

    .titleJa {
      @include setImg(480px); }

    .brand {
      margin-bottom: 56px;
      display: flex;

      li {
        opacity: 0.4;

        &+li {
          margin-left: 22px; }

        img {
          height: 24px; } } }

    .copy {
      @include setImg(466px);
      margin-bottom: 48px; } }

  .l-brand {
    .item {
      display: flex;
      align-items: center;
      position: relative;

      &:before {
        content: '';
        width: 50%;
        height: 100%;
        background: $gray-e;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1; }

      .img {
        @include setImg(50%); }

      .txts {
        text-align: center;
        width: 50%;
        padding: 80px 48px 80px 80px;
        box-sizing: border-box;

        .logo {
          margin-bottom: 48px; }

        .copy {
          @include setJa;
          @include setFont(21px, 1.5, 48px); }

        .txt {
          @include setMargin(14px, 2, 48px);
          text-align: left; }

        .btn {
          width: 192px;
          margin: 0 auto;

          a {
            font-size: 14px;
            line-height: 40px;
            width: 192px;
            height: 40px;
            border-radius: 20px; } } }

      &:nth-child(2n) {
        .img {
          order: 1; }

        .txts {
          order: 0;
          padding-right: 80px;
          padding-left: 56px; }

        &:before {
          left: auto;
          right: 0; } } } }

  .l-about {
    position: relative;
    margin-bottom: 112px;
    padding: 56px 0;

    .img {
      width: calc(100% - 210px);
      height: 100%;
      position: absolute;
      top: 0;
      left: 210px;
      z-index: -1;

      &.bijutsutecho {
        background: url(../../images/business/img_about_bijutsutecho.jpg) no-repeat 0 0;
        background-size: cover; }

      &.businesssolution {
        background: url(../../images/business/img_about_businesssolution.jpg) no-repeat 0 0;
        background-size: cover; }

      &.designcenter {
        background: url(../../images/business/img_about_designcenter.jpg) no-repeat 0 0;
        background-size: cover; }

      &.winart {
        background: url(../../images/business/img_about_winart.jpg) no-repeat 0 0;
        background-size: cover; }

      &.bijutsukentei {
        background: url(../../images/business/img_about_bijutsukentei.jpg) no-repeat 0 0;
        background-size: cover; } }

    .txts {
      width: 50%;
      background: $white;
      padding: 56px;
      box-sizing: border-box;
      a {
        color: $blue;
        text-decoration: none;

        &:before {
          content: '';
          background: url(../../images/common/icn_link.svg) no-repeat 0 0;
          width: 16px;
          height: 16px;
          display: inline-block;
          position: relative;
          top: 2px;
          margin-right: 6px; }
        &:hover {
          opacity: 0.5; } }

      .titleEn {
        @include setEn;
        color: $blue;
        font-size: 14px;
        margin-bottom: 48px; }

      .titleJa {
        @include setJa;
        @include setFont(36px, 1.5, 52px); } } }

  .l-business {
    border-bottom: 1px solid $gray-c;
    display: flex;
    flex-wrap: wrap;

    .item {
      width: 50%;

      .img {
        text-align: center;
        height: 400px;
        background: $gray-e;
        box-sizing: border-box;
        margin-bottom: 48px;
        padding: 0 40px;

        img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 320px;
          display: inline-block;
          position: relative;
          top: 50%;
          transform: translateY(-50%); } }

      .txts {
        padding: 0 48px 96px;

        .title {
          @include setJa;
          @include setFont(24px, 1.5, 44px); } }

      .link {
        margin-top: 48px; } } }

  .l-other {
    margin-bottom: 0;
    border-bottom: 0;
    padding-bottom: 0; } }
