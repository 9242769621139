@charset "utf-8";
@import "../lib/reset.min.css";
@import "../_icons.scss";
@import "../_variable.sass";
@import "../_mixin.sass";

body.career {
  .l-visual {
    margin-bottom: 136px;
    position: relative;

    .img {
      @include setImg(100%); }

    .txts {
      width: 836px;
      background: $white;
      box-sizing: border-box;
      padding: 56px 0 4px 56px;
      position: absolute;
      right: 0;
      bottom: 0;

      .titleEn {
        @include setEn;
        color: $blue;
        font-size: 14px;
        margin-bottom: 48px; }

      .titleJa {
        @include setJa;
        @include setFont(32px, 1.5, 40px);
        color: $black; }

      .subtitle {
        @include setJa;
        @include setFont(21px, 1.5, 24px); } } }

  .l-article {
    display: flex;
    margin-bottom: 96px;
    border-bottom: 1px solid $gray-c;
    padding-bottom: 96px;

    .l-sub {
      width: 418px;
      margin-right: 56px;

      .img {
        @include setImg(100%);
        margin-bottom: 56px; }

      .btn {
        a {
          line-height: 64px;
          width: 100%;
          height: 64px;
          border-radius: 32px; } } }

    .l-main {
      width: calc(100% - 474px);
      margin-bottom: -40;

      .date {
        @include setEn;
        color: $blue;
        font-size: 14px;
        margin-bottom: 40px;
        display: block; }

      .title {
        @include setJa;
        @include setFont(32px, 1.5, 80px); }

      .subtitle {
        @include setJa;
        @include setFont(24px, 1.5, 40px, 72px); }

      .txt {
        @include setFont(14px, 2, 40px);
        letter-spacing: 0.05em; }

      .btn {
        display: none; } } }

  .l-other {
    margin: 0;
    border: 0;
    padding: 0; } }
