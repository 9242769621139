@charset "utf-8";
@import "../lib/reset.min.css";
@import "../_icons.scss";
@import "../_variable.sass";
@import "../_mixin.sass";

body.books {
  .l-visual {
    margin-bottom: 136px;
    display: flex;
    position: relative;

    &:after {
      content: '';
      width: calc(100% - 56px);
      height: 100%;
      background: url(../../images/common/bg_brand_bijutsu.png) repeat 0 0;
      background-size: 384px 192px;
      position: absolute;
      top: 56px;
      left: 56px;
      z-index: -1; }

    &.bijutsuTecho {
      &:after {
        background: url(../../images/common/bg_brand_bijutsu_techo.png) repeat 0 0;
        background-size: 384px 192px; } }

    &.businessSolution {
      &:after {
        background: url(../../images/common/bg_brand_business_solution.png) repeat 0 0;
        background-size: 384px 192px; } }

    &.designCenter {
      &:after {
        background: url(../../images/common/bg_brand_design_center.png) repeat 0 0;
        background-size: 384px 192px; } }

    &.winart {
      &:after {
        background: url(../../images/common/bg_brand_winart.png) repeat 0 0;
        background-size: 384px 192px; } }

    &.bijutsuKentei {
      &:after {
        background: url(../../images/common/bg_brand_bijutsu_kentei.png) repeat 0 0;
        background-size: 384px 192px; } }

    .img {
      @include setImg(50%); }

    .txts {
      width: 50%;
      padding: 112px 56px 0;
      box-sizing: border-box;

      .date {
        @include setEn;
        color: $blue;
        font-size: 14px;
        margin-bottom: 36px;
        display: block; }

      .title {
        @include setJa;
        @include setFont(32px, 1.5, 40px);
        color: $black; }

      .tag {
        margin-bottom: 40px; }

      .subtitle {
        @include setJa;
        @include setFont(24px, 1.5, 48px); } } }

  .l-article {
    display: flex;

    .l-main,
    .l-sub {
      border-top: 1px solid $gray-c; }

    .l-main {
      margin: 0 56px -56px 0;
      padding: 0 56px;
      width: calc(100% - 420px);
      box-sizing: border-box;

      img {
        max-width: 100%;
        height: auto; }

      &>*:first-child {
        margin-top: 80px !important; }

      h1 {
        @include setJa;
        @include setFont(32px, 1.5, 56px, 80px); }

      h2 {
        @include setJa;
        @include setFont(24px, 1.5, 56px, 56px); }

      &>.img {
        @include setImg(calc(100% + 112px));
        margin: 80px 0 80px -56px; }

      &>.caption {
        @include setFont(14px, 1.5, 80px, -56px);
        width: calc(100% + 112px);
        color: $gray-a;
        text-align: center;
        margin-left: -56px; }

      .m-gallery {
        width: calc(100% + 112px);
        margin: 80px 0 80px -56px; }

      .title {
        @include setFont(14px, 1.5, 24px, 40px);
        color: $blue;
        font-weight: bold;
        text-align: justify;
        word-break: break-all; }

      .subtitle {
        @include setFont(14px, 1.5, 20px, 20px);
        color: $black;
        font-weight: bold; }

      .txt {
        @include setFont(14px, 2, 20px, 20px);
        text-align: justify;
        word-break: break-all;

        a {
          color: $blue;
          text-decoration: none;

          &:before {
            content: '';
            background: url(../../images/common/icn_link.svg) no-repeat 0 0;
            width: 16px;
            height: 16px;
            display: inline-block;
            position: relative;
            top: 2px;
            margin-right: 6px; }

          &:hover {
            opacity: 0.5; } } }

      .link {
        margin: 20px 0 28px; } }

    .l-sub {
      width: 364px;

      &>*:first-child {
        margin-top: 80px !important; }

      .btn {
        a {
          font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, sans-serif;
          font-size: 14px;
          font-weight: bold;
          line-height: 64px;
          height: 64px;
          border-radius: 32px;

          span {
            &:before {
              content: '';
              background: url(../../images/publication/icn_amazon.png) no-repeat 0 0;
              background-size: 18px 16px;
              width: 18px;
              height: 16px;
              display: inline-block;
              margin-right: 12px;
              position: relative;
              top: 4px; } }

          &:hover {
            span {
              &:before {
                filter: grayscale(100%) brightness(400%); } } } }

        &.buy {
          margin-bottom: 16px; }

        &.repeat {
          margin-bottom: 40px; } }

      .title {
        @include setFont(14px, 1.5, 24px, 40px);
        color: $blue;
        font-weight: bold;
        text-align: justify;
        word-break: break-all; }

      .txt {
        @include setFont(14px, 1.75);
        color: $gray-a;
        text-align: justify;
        word-break: break-all; }

      .info {
        .list {
          @include clearfix;
          margin-bottom: -24px;

          dt {
            @include setFont(14px, 1.5, 24px);
            color: $gray-a;
            font-weight: bold;
            width: 140px;
            float: left;
            clear: both; }

          dd {
            @include setFont(14px, 1.5, 24px);
            color: $gray-a;
            float: left; } } } } } }

