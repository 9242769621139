@charset "utf-8";
@import "../lib/reset.min.css";
@import "../_icons.scss";
@import "../_variable.sass";
@import "../_mixin.sass";

body.contact {
  .contact_form {
    overflow: hidden;
    height: auto;
    margin-top: 70px;
    padding-bottom: 70px;
    background: $gray-e; }

  .form_message {
    font-family: a-otf-midashi-go-mb31-pr6n,sans-serif;
    font-weight: 600;
    font-style: normal;
    -webkit-font-feature-settings: 'palt';
    font-feature-settings: 'palt';
    letter-spacing: .05em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 21px;
    line-height: 1.5;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    text-align: center;
    color: $black; }

  .txt {
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
    color: $gray-a; }

  .contact_form table {
    margin-top: 75px;
    margin-left: auto;
    margin-right: auto;
    text-align: left; }

  tr {
    margin-bottom: 10px; }

  th {
    width: 200px;
    vertical-align: middle;
    .item {
      font-size: 14px;
      font-weight: 600;
      color: $blue; }
    .required {
      color: $blue;
      font-size: 10px;
      font-feature-settings: 'palt';
      text-decoration: none;
      letter-spacing: .05em;
      margin-left: 14px;
      padding: 4px 8px;
      border: 1px solid $blue;
      border-radius: 20px;
      display: inline-block;
      box-sizing: border-box; } }

  td {
    width: 350px;
    vertical-align: middle;
    padding-top: 12px;
    padding-bottom: 12px; }

  select.category {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    background: $white;
    width: 640px;
    height: 50px;
    border: none;
    padding: 0 20px;

    &[disabled="disabled"] {
      background: transparent;

      &+.wpcf7c-conf-hidden+.arrow {
        display: none; } } }

  label[for="category"] {
    br {
      display: none; } }

  .arrow {
    position: absolute;
    top: 2px;
    right: 20px;
    font-size: 11px;
    color: $blue;
    pointer-events: none; }

  input {
    &.textbox {
      width: 600px;
      height: 50px;
      padding: 0 20px;
      margin: 0;
      border: none; }
    &.file {
      display: none; } }

  .upload p {
    display: inline-block;
    float: left; }

  .uploadButton {
    display: inline-block;
    position: relative;
    float: left;
    overflow: hidden;
    border-radius: 26px;
    border: 1px solid $blue;
    color: $blue;
    text-align: center;
    padding: 10px;
    line-height: 26px;
    width: 180px;
    cursor: pointer;
    margin-top: 5px;
    margin-bottom: 5px;
    &:hover {
      background: $blue;
      color: $white; }
    input[type=file] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      opacity: 0; } }

  .uploadValue {
    display: none;
    width: 360px;
    background: none;
    margin: 16px 20px;
    padding: 3px;
    border: none;
    font-feature-settings: 'palt'; }

  textarea {
    clear: both;
    width: 600px;
    height: 200px;
    padding: 20px;
    border: none; }

  .wpcf7-form {
    margin-bottom: 75px; }

  .wpcf7c-conf {
    border: 0;
    background: none; }

  .uploadButton input.wpcf7c-conf {
    background: none;
    display: none; }

  .accept {
    margin: 20px 0; }

  .accepttxt {
    margin-left: 10px; }

  span.wpcf7-list-item {
    margin: 0; }

  .submit-button {
    height: 70px;
    border-style: none;
    margin-top: 56px; }

  .confirm {
    text-align: center;
    input {
      width: 418px;
      height: 64px;
      border-style: none;
      line-height: 64px;
      border-radius: 32px;
      border: 1px solid $blue;
      background: $blue;
      color: $white;
      cursor: pointer;

      &:hover {
        background: transparent;
        color: $blue; } } }

  .back input {
    float: left;
    position: absolute;
    width: 160px;
    height: 64px;
    left: 10%;
    background: none;
    border: 1px solid $blue;
    border-radius: 32px;
    color: $blue;
    cursor: pointer;

    &:hover {
      background: $blue;
      color: $white; } }

  .submit input {
    clear: both;
    position: absolute;
    width: 418px;
    height: 64px;
    left: 37%;
    background: $blue;
    border: 1px solid $blue;
    border-radius: 32px;
    color: $white;
    cursor: pointer;

    &:hover {
      background: transparent;
      color: $blue; } }

  .submit-button input:disabled {
    display: block;
    border-radius: 32px;
    margin: 0 auto !important;
    font-feature-settings: 'palt';
    letter-spacing: .05em;
    background: none;
    color: $blue;
    text-align: center;
    text-decoration: none;
    border: 1px solid $blue;
    display: block;
    box-sizing: border-box; }

  .submit-button input:disabled {
    border: 1px solid $gray-c;
    color: $gray-c;
    pointer-events: none; }

  span.wpcf7-not-valid-tip {
    margin-top: 5px; }

  div {
    &.wpcf7-validation-errors, &.wpcf7-acceptance-missing {
      text-align: center;
      border: none; } }

  .wpcf7-validation-errors {
    display: none !important; }

  label[for="accept"] {
    width: 90%;
    margin: 2em auto;
    text-align: left; }


  div.wpcf7 .ajax-loader {
    display: none; } }

body.thanks {
  .form_message {
    @include setJa;
    font-size: 21px;
    line-height: 1.5;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    color: $black;
    margin-bottom: 50px; }

  .btn {
    a {
      font-size: 14px;
      letter-spacing: 0.05em; } } }
