@charset "utf-8";
@import "../lib/reset.min.css";
@import "../_icons.scss";
@import "../_variable.sass";
@import "../_mixin.sass";

body.privacy,
body.terms {
  .l-privacy,
  .l-terms {
    .title {
      @include setJa;
      @include setFont(24px, 1.5, 36px, 80px); }

    .txt {
      @include setMargin(14px, 2, 40px); } } }
