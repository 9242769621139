@charset "utf-8";
@import "../lib/reset.min.css";
@import "../_icons.scss";
@import "../_variable.sass";
@import "../_mixin.sass";

body {
  color: $black;
  font-size: 14px;
  font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, sans-serif;
  min-width: 1024px;
  padding: 56px;
  min-width: 960px;
  box-sizing: border-box;

  // &:before,
  // &:after
  //   content: ''
  //   width: 100%
  //   height: 56px
  //   background: $white
  //   z-index: 100
  //   position: fixed
  //   left: 0

  // &:before
  //   top: 0

  // &:after
 }  //   bottom: 0

li {
  list-style: none; }

.l-header {
  margin-bottom: 56px;
  display: flex;
  justify-content: space-between;

  .logo {
    @include setImg(300px);
    padding: 14px 40px 0 0; }

  .inner {
    .util {
      margin-bottom: 36px;
      display: flex;
      justify-content: flex-end;

      .search {
        .text {
          color: $black;
          font-size: 14px;
          height: 32px;
          width: 280px;
          background: $white;
          border: 0;
          border-bottom: 1px solid $gray-c;

          &:placeholder-shown {
            @include setEn;
            color: $gray-a;
            font-size: 12px; } }

        .submit {
          width: 32px;
          height: 32px;
          background: url(../../images/common/icn_search.svg) no-repeat 50% 50%;
          background-size: 20px 20px;
          border: 0;
          cursor: pointer;

          &:hover {
            opacity: 0.5; } } }

      .lang {
        display: flex;
        margin-left: 24px;

        .ja,
        .en {
          @include setEn;
          font-size: 14px;
          text-align: center;
          line-height: 32px;
          width: 32px;
          height: 32px;
          background: $gray-e;
          cursor: pointer;

          &:hover,
          &.is-enable {
            color: $white;
            background: $blue; } } } }

    .nav {
      display: flex;
      float: left;
      padding-right: 32px;
      border-right: 1px solid $gray-a;

      li {
        &+li {
          margin-left: 32px; }

        &.is-active {
          a {
            color: $blue; } }

        a {
          @include setEn;
          color: $black;
          font-size: 16px;
          text-decoration: none;

          &:hover {
            color: $blue; } } } }
    .sub-nav {
      display: flex;
      float: left;
      padding-left: 20px;
      li {
        float: left;
        &+li {
          margin-left: 20px; }

        &.is-active {
          a {
            color: $blue; } }
        a {
          @include setEn;
          color: #777;
          font-size: 13px;
          text-decoration: none;

          &:hover {
            color: $blue; } } } } } }

.l-title {
  background: $blue url(../../images/common/bg_title_pc.png) repeat-y 100% 0;
  background-size: 576px 288px;
  width: 100%;
  height: 288px;
  margin-bottom: 80px;
  padding: 114px 80px 0 80px;
  box-sizing: border-box;

  &.bijutsuTecho {
    background: $blue url(../../images/common/bg_title_bijutsu_techo_pc.png) repeat-y 100% 0;
    background-size: 576px 288px; }

  &.businessSolution {
    background: $blue url(../../images/common/bg_title_business_solution_pc.png) repeat-y 100% 0;
    background-size: 576px 288px; }

  &.designCenter {
    background: $blue url(../../images/common/bg_title_design_center_pc.png) repeat-y 100% 0;
    background-size: 576px 288px; }

  &.winart {
    background: $blue url(../../images/common/bg_title_winart_pc.png) repeat-y 100% 0;
    background-size: 576px 288px; }

  &.bijutsuKentei {
    background: $blue url(../../images/common/bg_title_bijutsu_kentei_pc.png) repeat-y 100% 0;
    background-size: 576px 288px; }

  .title {
    @include setJa;
    color: $white;
    font-size: 32px;
    text-shadow: $blue 1px 1px 0, $blue -1px -1px 0, $blue -1px 1px 0, $blue 1px -1px 0, $blue 0px 1px 0, $blue  0-1px 0, $blue -1px 0 0, $blue 1px 0 0;
    margin-bottom: 24px;

    img {
      margin-top: -6px;
      height: 40px; }

    .search {
      width: 480px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block; } }

  .topicpath {
    display: flex;

    li {
      @include setEn;
      color: $white;
      font-size: 12px;

      &+li {
        margin-left: 12px;

        &:before {
          content: '>';
          display: inline-block;
          margin-right: 12px; } }

      a {
        color: $white;
        text-decoration: none;

        &:hover {
          opacity: 0.5; } } } } }

.l-contact {
  background: $blue;
  margin-top: 120px;
  padding: 200px 0 80px;
  position: relative;

  &:before {
    content: '';
    background: url(../../images/common/icn_contact.svg) no-repeat 0 0;
    width: 45px;
    height: 36px;
    position: absolute;
    top: 80px;
    left: calc(50% - 22px); }

  .txt {
    @include setFont(14px, 2, 56px);
    color: $white;
    text-align: center;
    letter-spacing: 0.05em; }

  .btn {
    text-align: center;

    a {
      @include setEn;
      color: $white;
      font-size: 16px;
      text-align: center;
      text-decoration: none;
      line-height: 64px;
      width: 420px;
      height: 64px;
      border: 1px solid $white;
      border-radius: 32px;
      display: inline-block;

      &:hover {
        color: $blue;
        background: $white; } } } }

.l-footer {
  margin-top: 112px;
  display: flex;
  justify-content: space-between;

  .info {
    order: 0;

    .logo {
      @include setImg(300px);
      margin-bottom: 40px;
      opacity: 0.3; }

    .nav {
      margin-bottom: 40px;
      display: flex;

      li {
        &+li {
          margin-left: 20px;
          border-left: 1px solid $gray-c;
          padding-left: 20px; }

        a {
          @include setEn;
          color: $gray-a;
          font-size: 14px;
          text-decoration: none;

          &:hover {
            color: $blue; } } } }

    .copyright {
      @include setEn;
      color: $gray-a;
      font-size: 10px; } }

  .link {
    order: 1;

    .brand,
    .social {
      .title {
        @include setEn;
        color: $gray-a;
        font-size: 10px;
        margin-bottom: 24px; } }

    .brand {
      margin-bottom: 48px;

      .list {
        display: flex;
        position: relative;
        left: -1px;

        li {
          &+li {
            margin-left: 32px; }

          a {
            opacity: 0.4;

            &:hover {
              opacity: 0.2; }

            img {
              height: 24px;

              @media (max-width:1160px) {
                height: 20px; } } } } } }

    .social {
      .list {
        display: flex;

        li {
          a {
            &:hover {
              opacity: 0.5; } }

          &+li {
            margin-left: 24px; } } } } } }





