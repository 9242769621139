@charset "utf-8";
@import "../lib/reset.min.css";
@import "../_icons.scss";
@import "../_variable.sass";
@import "../_mixin.sass";

body.company {
  .l-company {
    margin-bottom: 72px;

    .items {
      display: flex;

      .wrap {
        width: calc(50% - 20px);

        &+.wrap {
          margin-left: 40px; }

        .item {
          display: flex;
          border-top: 1px solid $gray-c;
          padding: 24px 0;

          &:last-child {
            border-bottom: 1px solid $gray-c; }

          .label {
            color: $blue;
            font-size: 14px;
            letter-spacing: 0.05em;
            width: 96px; }

          .txt {
            @include setFont(14px, 2);
            letter-spacing: 0.05em;
            width: calc(100% - 96px); } } } } }

  .l-map {
    margin-bottom: 96px;

    iframe {
      width: 100%;
      height: 400px; } }

  .l-history {
    .items {
      .item {
        min-height: 160px;
        margin-bottom: 1px;
        display: flex;

        .year {
          width: 418px;
          background: $blue;
          text-align: center;

          .inner {
            width: 100%;
            position: relative;
            top: 50%;
            transform: translateY(-50%);

            .en {
              @include setEn;
              color: $white;
              font-size: 40px;
              letter-spacing: 0.05em;
              margin-bottom: 18px; }

            .ja {
              color: $white;
              font-size: 12px;
              font-weight: bold;
              letter-spacing: 0.05em; } } }

        .body {
          width: calc(100% - 418px);

          .inner {
            width: 100%;
            padding: 0 64px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            position: relative;
            top: 50%;
            transform: translateY(-50%);

            .img {
              height: 120px;
              margin-right: 40px;

              img {
                height: 100%; } }

            .txts {
              .title {
                @include setFont(18px, 1.5);
                color: $black;
                letter-spacing: 0.05em;
                font-weight: bold; }

              .txt {
                @include setFont(14px, 2, 0, 24px);
                color: $black;
                letter-spacing: 0.05em; } } } } } } } }


